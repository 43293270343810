import { CountryCode } from 'interfaces/Country'

export const getSupportPageLinks = (countryCode: CountryCode) => {
    switch (countryCode) {
        case CountryCode.UnitedStates:
        case CountryCode.UnitedKingdom:
        case CountryCode.Spain:
        case CountryCode.Colombia:
        case CountryCode.Turkey:
        case CountryCode.Mexico:
            return `https://knowunity-help.freshdesk.com/en/support/home`
        default:
            return `https://support.knowunity.com/en/support/home`
    }
}
